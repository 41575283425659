import Vue from 'vue'
import App from './App.vue'
import router from './router';
import ElementUI from 'element-ui';
import './styles/index.scss';
import request from '@/utils/request';
// import utils from '@/utils/index';
import locale from "element-ui/lib/locale/lang/zh-CN.js"
// 在安装 ElementUI 时设置 locale
Vue.use(ElementUI, {
  locale: locale,
  size: 'medium'
});
Vue.config.productionTip = false
Vue.prototype.$request = request;
// Vue.prototype.$utils = utils;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
