// 接口地址
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

// 项目名称
export const PROJECT_NAME = process.env.VUE_APP_NAME;

// 不显示侧栏的路由
export const HIDE_SIDEBARS = [];

// 不显示页脚的路由
export const HIDE_FOOTERS = [
  '/system/dictionary',
  '/system/organization',
  '/form/advanced'
];

// 页签同路由不同参数可重复打开的路由
export const REPEATABLE_TABS = [];

// 不需要登录的路由
export const WHITE_LIST = ['/login', '/forget'];

// 开启 KeepAlive 后仍然不需要缓存的路由地址
export const KEEP_ALIVE_EXCLUDES = [];

//直接指定菜单数据
// export const USER_MENUS = [
//   {
//     path: '/system',
//     redirect: '/system/user',
//     meta: { title: '系统管理', icon: 'el-icon-setting' },
//     children: [
//       {
//         path: '/system/user',
//         component: '/system/user',
//         meta: { title: '用户管理', icon: 'el-icon-user', hide: false }
//       },
//       {
//         path: '/system/role',
//         component: '/system/role',
//         meta: { title: '角色管理', icon: 'el-icon-postcard', hide: false }
//       },
//       {
//         path: '/system/menu',
//         component: '/system/menu',
//         meta: { title: '菜单管理', icon: 'el-icon-menu', hide: false }
//       },
//       {
//         path: '/system/village-manage',
//         component: '/system/village-manage',
//         meta: {
//           title: '网点管理',
//           icon: 'el-icon-office-building',
//           hide: false
//         }
//       },
//       {
//         path: '/system/property-personnel',
//         component: '/system/property-personnel',
//         meta: {
//           title: '物业人员管理',
//           icon: 'el-icon-help',
//           hide: false
//         }
//       },
//       {
//         path: '/system/recycling-personnel',
//         component: '/system/recycling-personnel',
//         meta: {
//           title: '回收人员管理',
//           icon: 'el-icon-_user-group',
//           hide: false
//         }
//       }
//     ]
//   },
//   {
//     path: '/order',
//     redirect: '/order/recycling-orders',
//     meta: { title: '回收订单管理', icon: 'el-icon-_template' },
//     children: [
//       {
//         path: '/order/recycling-orders',
//         component: '/order/recycling-orders',
//         meta: { title: '回收订单', icon: 'el-icon-document-copy', hide: false }
//       },
//       // {
//       //   path: '/order/transport',
//       //   component: '/order/transport',
//       //   meta: { title: '转运申请', icon: 'el-icon-_visa', hide: false }
//       // },
//       {
//         path: '/order/await-warehousing',
//         component: '/order/await-warehousing',
//         meta: { title: '待入库订单', icon: 'el-icon-truck', hide: false }
//       },
//       {
//         path: '/order/warehousing',
//         component: '/order/warehousing',
//         meta: { title: '已入库订单', icon: 'el-icon-tickets', hide: false }
//       }
//     ]
//   },
//   {
//     path: '/transport-plan',
//     component: '/transport-plan',
//     meta: { title: '转运计划', icon: 'el-icon-wind-power', hide: false },
//     children: []
//   },
//   {
//     path: '/recovery-settlement',
//     redirect: '/recovery-settlement/config-settlement',
//     meta: { title: '回收结算管理', icon: 'el-icon-suitcase' },
//     children: [
//       {
//         path: '/recovery-settlement/config-settlement',
//         component: '/recovery-settlement/config-settlement',
//         meta: {
//           title: '配置结算比例',
//           icon: 'el-icon-pie-chart',
//           hide: false
//         }
//       }
//       // {
//       //   path: '/recovery-settlement/settlement-record',
//       //   component: '/recovery-settlement/settlement-record',
//       //   meta: { title: '结算记录', icon: 'el-icon-tickets', hide: false }
//       // }
//     ]
//   },
//   {
//     path: '/other-order',
//     component: '/other-order',
//     meta: { title: '工业农业订单', icon: 'el-icon-_table', hide: false },
//     children: []
//   },
//   {
//     path: '/carousel',
//     component: '/carousel',
//     meta: { title: '轮播管理', icon: 'el-icon-picture-outline', hide: false },
//     children: []
//   },
//   {
//     path: '/realty-management',
//     redirect: '/realty-management/recycling-personnel',
//     meta: { title: '物业管理', icon: 'el-icon-files' },
//     children: [
//       {
//         path: '/realty-management/recycling-personnel',
//         component: '/realty-management/recycling-personnel',
//         meta: { title: '回收人员列表', icon: 'el-icon-user', hide: false }
//       },
//       {
//         path: '/realty-management/settlement-records',
//         component: '/realty-management/settlement-records',
//         meta: { title: '结算记录', icon: 'el-icon-document', hide: false }
//       },
//       {
//         path: '/realty-management/apply-for',
//         component: '/realty-management/apply-for',
//         meta: { title: '物业物料申请', icon: 'el-icon-document', hide: false }
//       }
//     ]
//   },
//   {
//     path: '/files-upload',
//     component: '/files-upload',
//     meta: { title: '签约文件上传', icon: 'el-icon-document', hide: false },
//     children: []
//   }
// ];
export const USER_MENUS = undefined;
// 首页名称, 为空则取第一个菜单的名称
export const HOME_TITLE = undefined;

// 首页路径, 为空则取第一个菜单的地址
export const HOME_PATH = undefined;

// 外层布局的路由地址
export const LAYOUT_PATH = '/';

// 刷新路由的路由地址
export const REDIRECT_PATH = '/redirect';

// 开启页签栏是否缓存组件
//export const TAB_KEEP_ALIVE = process.env.NODE_ENV !== 'development';
export const TAB_KEEP_ALIVE = false;

// token 传递的 header 名称
export const TOKEN_HEADER_NAME = 'Authorization';

// token 存储的名称
export const TOKEN_STORE_NAME = 'access_token';

// 主题配置存储的名称
export const THEME_STORE_NAME = 'theme';

// i18n 缓存的名称
export const I18N_CACHE_NAME = 'i18n-lang';

// 是否开启国际化功能
export const I18N_ENABLE = false;

// 高德地图 key , 自带的只能用于测试, 正式项目请自行到高德地图官网申请 key
export const MAP_KEY = 'f2d47cffd91d2d5e75ab9eb106d69087';

// EleAdmin 授权码, 自带的只能用于演示, 正式项目请更换为自己的授权码
export const LICENSE_CODE =
  'dk9mcwJyetRWQlxWRiojIqJWdzJCLi4Wa4ADO3ojI0NWZHpkI6ICZpJCLiwiIrBncKd1Q6Iibvl2cyVmdQfiETMuEjI0NW==';
