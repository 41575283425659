import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken ,setToken} from '@/utils/token-util';
Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
 
];

const router = new VueRouter({
  routes
});
var url = window.location.href;
let decodeDtr= '';
if(url.indexOf('?')!==-1){
	var cs = url.split('?')[1];
	var csToken = cs.split('=')[1]
	decodeDtr = decodeURIComponent(csToken);
	// localStorage.setItem('access_token','Bearer ' +decodeDtr);
}
		
router.beforeEach((to, from, next) => {
  if (getToken() ) {
    next();
  }else if(decodeDtr){
	  setToken(decodeDtr);
	  next();
  } else {
    if (to.path == '/login') {
      next();
      console.log('在登录页了')
    } else {
      console.log('1111')
      next({
        path: '/login'
      });
    }
  }
});
export default router;
