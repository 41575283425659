<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
	name: 'App',
	data() {
		return {
			
		};
	},
};
</script>

<style>
@import url('./styles/body.css');
@import url('./styles/common.scss');
@import url('./assets/iconfont/iconfont.css');

#app {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	color: #fff;
	font-size: 16rem;
	min-width: 1200px;
}
</style>
